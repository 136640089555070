import React from "react";
import PropTypes from "prop-types";

import ArtistListItem from "./ArtistListItem";

const Artists = ({ artists, lang, langKey, location }) => {
  return (
		<ul id="artists" className="wrapper artists">
      {artists.map(artist => (
				<ArtistListItem key={artist.name} {...artist} lang={lang} />
      ))}
    </ul>
  );
};

Artists.propTypes = {
  artists: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Artists;