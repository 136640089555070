import React from 'react';
import { graphql } from "gatsby";

// import pic1 from '../../../images/nature-in-transition/pic01.jpg';
import Layout from '../../../components/common/layout';

import Artists from '../../../components/artists/Artists';
// import Header from '../components/common/Header';


const ArtistsPage = ({location, data}) => (
  <Layout location={location}>

    <div id="wrapper">
			<section id="main" className="wrapper">
			<header className="page-header">
					{/* {hasFeatured ? <GatsbyImage src={featuredImgFluid} /> : ''} */}
					<div className="heading">
          	<h1 className="major">Artists</h1>
					</div>
				</header>
				<div className="text-3xl font-serif">
					<Artists artists={data.natureintransitionJson.artists} />
        </div>
      </section>
    </div>

  </Layout>
);

export default ArtistsPage;


export const ArtistsQuery = graphql`
  query ArtistsQuery {
		natureintransitionJson {
			artists {
				name
				country
				to {
					title
					href
					label
				}
			}
		}
  }
`;
